.grades table {
  width: 100%;
  max-width: 100%;
}
.grades .panel .panel-heading {
  font-size: 1.3rem;
  color: #2b5236;
  margin-top: 1rem;
  font-weight: bold;
}
