html {
    font-family: Ubuntu Condensed;
    font-size: 1em;
    color: #5e7163;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: #fefdf6;
  }
  body {
    display: flex;
    flex-direction: column;
  }
  selection {
    background: #5e7163;
    color: #fefdf6;
  }
  ahover {
    color: #aa974d;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: #2a5135;
  }
  h2 {
    border-bottom: solid 1px rgba(68,131,86,0.3);
  }
  main {
    flex: 1 0 auto;
    font-size: 1.2em;
    word-break: break-word;
  }
  header,
  footer {
    flex: none;
  }
  .sheet {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
  }
  .sheet-content {
    padding-top: 15px;
    flex: 100;
  }
  .sheet-content >div:first-child h2,
  .sheet-content >article:first-child h2,
  .sheet-content:first-child h2 {
    margin-top: 0;
    border-bottom: none;
  }
  img {
    height: auto;
    max-width: 100%;
  }
  tbody tr tdfirst-child {
    white-space: nowrap;
  }
  tbody tr td {
    border-top: 1px solid #5e7163;
    border-right: 1px solid #5e7163;
  }
  tbody tr td:last-child {
    border-right: none;
  }
  .anchor {
    padding-top: 100px;
    margin-top: -100px;
  }
  .mobile-only,
  .mobile-block,
  .mobile-flex {
    display: none !important;
  }
  header {
    z-index: 21;
    position: relative;
    height: 400px;
  }
  #header-top {
    background: #2a5135;
    min-height: 50px;
    z-index: 10;
    position: relative;
  }
  #header-top .sheet {
    justify-content: space-between;
  }
  #header-top a.logo {
    display: inline-block;
    width: 300px;
    height: 50px;
    background: url("./images/logo.svg") center center/contain no-repeat;
  }
  #header-alert {
    background: #aa974d;
  }
  #header-message {
    background: #448356;
  }
  #header-alert,
  #header-message {
    z-index: 10;
    position: relative;
  }
  #header-alert .sheet,
  #header-message .sheet {
    justify-content: space-around;
  }
  #header-alert .sheet > p:only-child,
  #header-message .sheet > p:only-child {
    text-align: center;
    width: 100%;
  }
  #header-alert *,
  #header-message * {
    color: #fff;
  }
  #header-bottom {
    transition: background 0.5s ease;
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
    position: relative;
  }
  #header-bottom .sheet {
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
  }
  #header-bottom .title {
    display: flex;
    flex-shrink: 0;
  }
  #header-bottom .title h1 {
    margin: 0;
    line-height: 3em;
    font-size: 2em;
  }
  #header-bottom .title h1 a {
    text-decoration: none;
  }
  #header-bottom .title h1 a:hover {
    color: #448356;
  }
  #header-bottom nav {
    line-height: 2em;
  }
  #header-bottom nav a {
    font-size: 1.4em;
    text-decoration: none;
    padding: 10px 0;
    margin: 0 6px;
    white-space: nowrap;
  }
  #header-bottom nav a:hover {
    color: #c55628;
    border-bottom: solid 2px #c55628;
  }
  #header-bottom.sticky {
    position: relative;
    top: 0;
    z-index: 25;
  }
  #header-bottom.stuck {
    position: fixed;
    width: 100%;
    top: 0;
    background: rgba(255,255,255,0.85);
  }
  button#mobile-menu {
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 3px 7px;
    margin-right: 6px;
  }
  button#mobile-menu:hover {
    background: #2a5135;
  }
  button#mobile-menu:hover span.bar {
    border-color: #fefdf6;
  }
  button#mobile-menu span.bar {
    display: block;
    border: solid 1px #2a5135;
    height: 0px;
    width: 1.5em;
    margin: 5px;
  }
  nav.sidemenu {
    vertical-align: top;
    padding-top: 15px;
  }
  nav.sidemenu h2 {
    margin: 10px 0 !important;
    white-space: nowrap;
    font-size: 1.2em;
    border-bottom: none;
  }
  nav.sidemenu a {
    display: block;
    padding: 5px 20px;
    text-decoration: none;
    white-space: nowrap;
    transition: background 0.5s ease;
  }
  nav.sidemenu a:hover {
    background: rgba(0,0,0,0.1);
  }
  nav.sidemenu >* {
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
    border-left: solid 1px #d4d6d2;
    list-style: none;
  }
  nav.sidemenu >* >li ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  footer {
    padding-top: 10px;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
  }
  footer a {
    color: #448356;
  }
  #footer-top {
    padding: 25px 0;
    background: #294f33;
  }
  #footer-top .sheet {
    justify-content: space-around;
  }
  #footer-top ul {
    list-style: none;
    padding: 0;
    text-align: center;
  }
  #footer-top ul li {
    display: inline-block;
    margin: 0 10px;
  }
  #footer-bottom {
    background: #274b31;
    color: #448356;
    padding: 25px 0;
  }
  #footer-bottom .sheet {
    justify-content: space-between;
  }
  #footer-links {
    display: flex;
    flex-wrap: wrap;
  }
  #footer-links a {
    margin-left: 10px;
  }
  #top-cover-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    overflow: hidden;
  }
  #top-cover-image-inner {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 110%;
    background: url("./images/oval.jpg");
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
  #tabs.no-js ul.tabs-nav {
    display: none;
  }
  #tabs.no-js .tab-data {
    display: block;
    margin-bottom: 2rem;
  }
  #tabs.no-js .tab-data:last-child {
    margin-bottom: 0;
  }
  #tabs ul.tabs-nav {
    display: flex;
    list-style: none;
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px 0;
    padding: 0;
  }
  #tabs ul.tabs-nav li.tabs-nav-link {
    margin-right: 4px;
    padding: 10px;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom: none;
    position: relative;
    bottom: -1px;
  }
  #tabs ul.tabs-nav li.tabs-nav-link:last-child {
    margin-right: 0;
  }
  #tabs ul.tabs-nav li.tabs-nav-link.active,
  #tabs ul.tabs-nav li.tabs-nav-link:hover {
    color: #000;
    border-color: #aaa;
    background: #fff;
    border-radius: 5px 5px 0 0;
  }
  #tabs .tab-data {
    display: none;
  }
  #tabs .tab-data.active {
    display: block;
  }
  .toggle input[type="checkbox"] {
    display: none;
  }
  .toggle input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .toggle input[type="checkbox"] + label h1,
  .toggle input[type="checkbox"] + label h2,
  .toggle input[type="checkbox"] + label h3,
  .toggle input[type="checkbox"] + label h4,
  .toggle input[type="checkbox"] + label h5,
  .toggle input[type="checkbox"] + label h6 {
    margin: 0;
  }
  .toggle input[type="checkbox"] + label:hover * {
    color: #aa974d;
  }
  .toggle input[type="checkbox"] + label::before {
    content: '▶';
    width: 20px;
    height: 20px;
    font-size: 0.6em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s;
  }
  .toggle input[type="checkbox"]:checked + label::before {
    transform: rotate(90deg);
  }
  .toggle input[type="checkbox"] ~ .panel {
    transition: all 250ms linear, opacity 200ms ease-out;
  }
  .toggle input[type="checkbox"]:not(:checked) ~ .panel {
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
    border-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .toggle input[type="checkbox"]:checked ~ .panel {
    visibility: visible;
    opacity: 1;
  }
  @media (max-width: 1000px) {
    header {
      height: 40vw;
    }
    #header-bottom .title h1 {
      font-size: 3vw;
    }
    #header-bottom nav a {
      font-size: 2.5vw;
    }
    #bottom-cover-image-wrapper {
      height: 30vw;
    }
  }
  @media (max-width: 600px) {
    .mobile-only,
    .mobile-block {
      display: block !important;
    }
    .mobile-flex {
      display: flex !important;
    }
    .mobile-none {
      display: none !important;
    }
    header {
      text-align: center;
      min-height: 200px;
    }
    #header-bottom .sheet {
      padding-left: 0;
    }
    #header-bottom nav {
      display: none;
    }
    #header-bottom .title h1 {
      font-size: 1.4em;
    }
    button#mobile-menu {
      display: inline-block;
    }
    .sheet {
      padding: 0 6px;
    }
    nav.sidemenu {
      min-width: 250px;
      width: 66%;
      height: 100%;
      position: fixed;
      z-index: 50;
      left: 0;
      top: 0;
      margin-left: 0;
      padding-top: 0;
      transition: left 0.2s ease;
      background-color: #fefdf6;
      line-height: 40px;
      font-size: 1.2em;
      box-shadow: 0 0 10px 10px rgba(0,0,0,0.2);
      overflow-x: hidden;
      overflow-y: auto;
    }
    nav.sidemenu.away {
      left: -100%;
    }
    nav.sidemenu ul {
      margin: 0;
      padding: 0;
      border-left: 0;
    }
    nav.sidemenu ul li {
      text-align: left;
    }
    nav.sidemenu h2 {
      margin-left: 10px;
      font-size: 5vw;
    }
    nav.sidemenu a {
      border-bottom: solid 4px #448356;
      margin: 0;
      padding: 10px 0 10px 20px;
      font-size: 4.5vw;
      white-space: normal;
    }
  }
  @media (max-width: 400px) {
    #header-bottom h1 {
      overflow: hidden;
      white-space: nowrap;
      font-size: 5vw !important;
      display: block;
    }
    nav.sidemenu ul li a {
      font-size: 18px;
    }
    nav.sidemenu ul li h2 {
      font-size: 20px;
    }
  }
  